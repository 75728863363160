import { UnitParams } from '~/types/Unit';
import { MagicUseCase } from '../magicUseCase';

export class UpdateListingSearchParamsUseCase extends MagicUseCase {
  protected async runLogic(params: UnitParams) {
    this.getState().user.unitParams = params;
    this.getState().user.companyUnitsListing = undefined;
    this.getState().user.prevCompanyUnitsListingItems = [];
  }
}
