import IconBathroom from '~/assets/images/home/bathroom.svg';
import IconArea from '~/assets/images/home/ft.svg';
import IconRoom from '~/assets/images/home/room.svg';
import { Skeleton } from '../common/Skeleton';

export const PropertyItemSkeleton = () => {
  return (
    <div style={{ 'box-shadow': '0px 12px 36px 0px #3B4D8114' }} class="w-full cursor-pointer overflow-hidden rounded-lg">
      <div class="h-[240px] gap-[6px]">
        <Skeleton class="!size-full" />
      </div>

      <div class="p-5">
        <div class="h-6">
          <Skeleton class="!size-full" />
        </div>
        <div class="mt-3 h-9">
          <Skeleton class="!size-full" />
        </div>
        <div class="mt-5 flex gap-6 text-base text-text-level03">
          <div class="flex items-center gap-1">
            <img src={IconRoom} alt="" /> <Skeleton class="w-8" />
          </div>
          <div class="flex items-center gap-1">
            <img src={IconBathroom} alt="" /> <Skeleton class="w-8" />
          </div>
          <div class="flex items-center gap-1">
            <img src={IconArea} alt="" /> <Skeleton class="w-8" />
          </div>
        </div>

        <div class="my-5 h-px w-full bg-[#E7E9EB]" />

        <div class="h-8">
          <Skeleton class="!size-full" />
        </div>
      </div>
    </div>
  );
};
