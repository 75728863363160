import {
  OR_LESS,
  OR_MORE,
  ANY_PRICE,
  ANY,
  SINGLE_FAMILY,
  MULTI_FAMILY,
  COMMERCIAL,
  CONDO,
  TOWN_HOME,
  OTHER,
  TOWN_HOUSE,
  SINGLE_FAMILY_DISPLAY,
  MULTI_FAMILY_DISPLAY,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { useLocalization } from '~/hooks/useLocalization';
import { User } from '~/state/mainAppState';
import { PresentUnitParams } from '~/types/Unit';

const { t } = useLocalization();

export class CompanyUnitSearchParamsPresenter extends Presenter<PresentUnitParams> {
  protected createModel(state: User): PresentUnitParams {
    const searchParams = {
      availableFrom: state.unitParams?.availableFrom,
      maxRentalPrice: state.unitParams?.maxRentalPrice,
      minBaths: state.unitParams?.minBaths,
      minBeds: state.unitParams?.minBeds,
      minRentalPrice: state.unitParams?.minRentalPrice,
      page: state.unitParams?.page,
      pageSize: state.unitParams?.pageSize,
      pinned: state.unitParams?.pinned,
      propertyTypes: state.unitParams?.propertyTypes,
      search: state.unitParams?.search,
    };
    return {
      searchParams,
      priceRangeOptions: this.getPriceRangeOptions(),
      minPriceOptions: this.getSinglePriceOptions(),
      maxPriceOptions: this.getSinglePriceOptions(),
      homeTypeOptions: this.getHomeTypeOptions(),
      bedOptions: this.getBedOptions(),
      bathOptions: this.getBathOptions(),
      priceDisplay: this.getPriceDisplay(
        state.unitParams?.minRentalPrice?.toString() ?? '',
        state.unitParams?.maxRentalPrice?.toString() ?? ''
      ),
      bdBaDisplay: this.getBdBaDisplay(state.unitParams?.minBaths?.toString() ?? '', state.unitParams?.minBeds?.toString() ?? ''),
      propertyTypesDisplay: this.getHomeTypeDisplay(state.unitParams?.propertyTypes ?? []),
    };
  }

  private getHomeTypeOptions() {
    return [
      { label: t(SINGLE_FAMILY_DISPLAY), value: SINGLE_FAMILY },
      { label: t(MULTI_FAMILY_DISPLAY), value: MULTI_FAMILY },
      { label: t(COMMERCIAL), value: COMMERCIAL },
      { label: t(CONDO), value: CONDO },
      { label: t(TOWN_HOUSE), value: TOWN_HOME },
      { label: t(OTHER), value: OTHER },
    ];
  }

  private getHomeTypeDisplay(propertyTypes: string[]) {
    if (propertyTypes?.length === this.getHomeTypeOptions().length) {
      return '';
    }
    const homeTypeOptions = this.getHomeTypeOptions();
    return propertyTypes
      .map((type) => homeTypeOptions.find((option) => option.value === type)?.label || '')
      .filter((label) => label !== '')
      .join(', ');
  }

  private getBathOptions() {
    return [
      { label: t(ANY), value: '' },
      { label: '1+', value: '1' },
      { label: '1.5+', value: '1.5' },
      { label: '2+', value: '2' },
      { label: '2.5+', value: '2.5' },
      { label: '3+', value: '3' },
      { label: '4+', value: '4' },
    ];
  }

  private getBedOptions() {
    return [
      { label: t(ANY), value: '' },
      { label: '1+', value: '1' },
      { label: '2+', value: '2' },
      { label: '3+', value: '3' },
      { label: '4+', value: '4' },
      { label: '5+', value: '5' },
    ];
  }

  private getBdBaDisplay(bd: string, ba: string) {
    if (!bd && !ba) {
      return '';
    }

    if (!bd) {
      return `${ba}+ ba`;
    }

    if (!ba) {
      return `${bd}+ bd`;
    }

    return `${bd}+ bd, ${ba}+ ba`;
  }

  private getPriceDisplay(min: string, max: string) {
    if (!min && !max) {
      return '';
    }

    if (!min) {
      return `${currency(max)} ${t(OR_LESS)}`;
    }

    if (!max) {
      return `${currency(min)} ${t(OR_MORE)}`;
    }

    return `${currency(min)}-${currency(max)}`;
  }

  private getSinglePriceOptions() {
    return [
      { label: t(ANY_PRICE), value: '' },
      { label: `${currency(750)}`, value: '750' },
      { label: `${currency(1000)}`, value: '1000' },
      { label: `${currency(1250)}`, value: '1250' },
      { label: `${currency(1500)}`, value: '1500' },
      { label: `${currency(1750)}`, value: '1750' },
      { label: `${currency(2000)}`, value: '2000' },
      { label: `${currency(2250)}`, value: '2250' },
      { label: `${currency(2500)}`, value: '2500' },
      { label: `${currency(2750)}`, value: '2750' },
      { label: `${currency(3000)}`, value: '3000' },
      { label: `${currency(3250)}`, value: '3250' },
      { label: `${currency(3500)}`, value: '3500' },
      { label: `${currency(3750)}`, value: '3750' },
      { label: `${currency(4000)}`, value: '4000' },
      { label: `${currency(5000)} ${t(OR_MORE)}`, value: '5000' },
    ];
  }

  private getPriceRangeOptions() {
    return [
      { label: `${currency(1499)} ${t(OR_LESS)}`, value: '0-1499' },
      { label: `${currency(1500)}-${currency(1999)}`, value: '1500-1999' },
      { label: `${currency(2000)}-${currency(2499)}`, value: '2000-2499' },
      { label: `${currency(2500)}-${currency(2999)}`, value: '2500-2999' },
      { label: `${currency(3000)}-${currency(3499)}`, value: '3000-3499' },
      { label: `${currency(3500)}-${currency(3999)}`, value: '3500-3999' },
      { label: `${currency(4000)} ${t(OR_MORE)}`, value: '4000-0' },
    ];
  }
}
