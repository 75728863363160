import { createSignal, createEffect, splitProps } from 'solid-js';
import type { Component, ComponentProps } from 'solid-js';
import { Calendar } from '~/components/ui/Calendar';
import { IconCalendar } from '~/components/ui/Icons';
import { Popover } from '~/components/ui/Popover';
import { useLocalization } from '~/hooks/useLocalization';
import { cn } from '~/utils/classnames';
import { debounce } from '~/utils/tools';
import type { Merge } from '~/utils/types';

const DATE_REGEX = /^\d{1,2}\/\d{1,2}\/\d{2}(\d{2})?$|^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/;

export type DatePickerProps = Merge<
  ComponentProps<'input'>,
  {
    value?: string;
    onChange?: (value: string | null) => void;
    yearRange?: 'general' | 'birthday';
    disabledDate?: (date: Date) => boolean;
  }
>;

const parseDate = (date?: string) => {
  if (!date) {
    return undefined;
  }
  const [year, month, day] = date.split(/[-/T\s:]/).map(Number);
  return new Date(year, month - 1, day);
};

const formatDate = (locale: string, date?: Date) =>
  date ? new Intl.DateTimeFormat(locale, { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date) : '';

const DatePicker: Component<DatePickerProps> = (props) => {
  const { t, currentLanguage } = useLocalization();
  const [params, rest] = splitProps(props, ['value', 'onChange', 'class', 'yearRange', 'disabledDate']);
  const [date, setDate] = createSignal<Date>();
  createEffect(() => setDate(parseDate(params.value)));

  const changeDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    props.onChange?.(`${year}-${month}-${day}`);
    setDate(date);
  };

  // eslint-disable-next-line solid/reactivity
  const handleChange = debounce((e: { target: HTMLInputElement }) => {
    e.target.value = e.target.value.replace(/[^0-9/-]/g, '');
    const input = e.target.value;
    if (!input) {
      props.onChange?.(null);
      return setDate(undefined);
    }
    if (!DATE_REGEX.test(input)) return;
    const date = new Date(input);
    if (isNaN(date.getTime())) return;
    changeDate(date);
  }, 500);

  return (
    <Popover
      class="relative"
      onOutsideClick={(e: MouseEvent) => (e.currentTarget as Node).contains(e.relatedTarget as Node) && e.preventDefault()}>
      {({ setOpen }) => (
        <>
          <input
            type="tel"
            placeholder={t('MM/DD/YYYY')}
            {...rest}
            value={formatDate(currentLanguage(), date())}
            onFocus={() => setOpen(true)}
            onBlur={(e) => {
              e.target.value = formatDate(currentLanguage(), date());
            }}
            onInput={handleChange}
            class={cn(
              'focus:ring-essential-colour w-full rounded-md border bg-inputbox-bg px-3 py-2 text-sm text-black outline-none placeholder:text-auxiliary-text focus:ring-1',
              params.class
            )}
          />
          <IconCalendar
            class="absolute inset-y-0 right-0 mx-3 my-2 size-5 cursor-pointer text-text-level02"
            onClick={() => {
              !props.disabled && setOpen((prev) => !prev);
            }}
          />
          <Popover.Content
            as={Calendar}
            align="start"
            class="my-1 rounded-md border bg-white"
            selected={date()}
            yearType={params.yearRange}
            onSelect={(date: Date) => {
              changeDate(date);
              setOpen(false);
            }}
            disabledDate={params.disabledDate}
          />
        </>
      )}
    </Popover>
  );
};

export { DatePicker };
