import { companyUnitListingGateway } from '~/gateways/companyUnitListingGateway';
import { UnitParams } from '~/types/Unit';
import { MagicUseCase } from '../magicUseCase';

export class GetCompanyUnitsListingUseCase extends MagicUseCase {
  protected async runLogic(params: UnitParams) {
    const response = await companyUnitListingGateway.getCompanyUnitsListing(params);
    if (response.currentPage === 1) {
      this.getState().user.prevCompanyUnitsListingItems = [];
    } else {
      this.getState().user.prevCompanyUnitsListingItems = [
        ...(this.getState().user.prevCompanyUnitsListingItems || []),
        ...(this.getState().user.companyUnitsListing?.items || []),
      ];
    }

    this.getState().user.companyUnitsListing = { ...response };
  }
}
